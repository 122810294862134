import React from "react";


import Layout from "../layouts/app";

export default () => (
  <Layout>
    <h1 className="mucapp"> 404: Page Not Found</h1>
  </Layout >
);
